vue
<template>
    <div>
        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <p class="title-bold">Hilos tensores corporales</p>
            </div>
        </div>

        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <img class="img-fluid" alt="hilos tensores corporales" src="../../assets/hilos-tensores-corporales.svg" />
            </div>

            <div class="p-col-10 p-offset-1 p-d-flex p-jc-center">
                <Card styleClass="p-card-shadow">
                    <template #content>
                        <p>Los hilos tensores son una gran herramienta para la medicina estética cuando el objetivo es
                            conseguir mayor elasticidad cutánea.</p>
                        <p>En Centro Médico Beatriz Ayllón trabajamos con hilos biocompatibles y reabsorbibles para el
                            cuerpo.</p>
                        <p class="p-text-bold">¿Cómo funcionan los hilos tensores corporales?</p>
                        <p>Los hilos tensores trabajan la flacidez de la piel; producen un lifting autoinducido, es decir,
                            al insertar los hilos, nuestro cuerpo comienza a generar <span
                                class="p-text-bold">colágeno</span> y <span class="p-text-bold">elastina</span> alrededor
                            del mismo. Es un tratamiento <span class="p-text-bold">mínimamente invasivo</span> y no doloroso
                            que consigue un efecto muy duradero y natural. Los hilos tensores funcionan en diferentes partes
                            del cuerpo, como en la cara interna de los brazos, la cara interna de los muslos, glúteos, mamas
                            y abdomen.</p>
                        <p>Una de las grandes ventajas de este tratamiento es que sólo requiere de una sesión, que apenas
                            dura una hora como máximo y que no requiere ni anestesia ni hospitalización. La inserción de los
                            hilos es muy sencilla, se hace a nivel dérmico mediante finas agujas, cubriendo así la zona
                            afectada por la flacidez.</p>
                        <p>No es necesario retirar los hilos ya que éstos se reabsorben al cabo de 6 u 8 meses, y tienen una
                            duración de entre un año y medio a dos años, pudiendo volver a colocarlos nuevamente
                            transcurrido el tiempo. El resultado es un efecto lifting de la zona tratada que se puede
                            compatibilizar con mesoterapia o aparatología y deporte para mejorar sus resultados.</p>

                    </template>

                    <template #footer>
                        <span>Para más información no dude en ponerse en contacto con nuestro
                            Centro médico al 637 857 384.</span>
                    </template>
                </Card>
            </div>
        </div>
    </div>
</template>

<script>
import Card from 'primevue/card';
export default {
    name: 'HilosCorporalesVue',

    data() {
        return {
            // Propiedades de datos del componente
        };
    },
    components: {
        Card
    },
    methods: {
        // Métodos del componente
    },
    computed: {
        // Propiedades computadas del componente
    },
    created() {
        // Lógica que se ejecuta cuando el componente se crea
    },
    mounted() {
        // Lógica que se ejecuta cuando el componente se monta en el DOM
    },
    destroyed() {
        // Lógica que se ejecuta cuando el componente se destruye
    },
};
</script>

<style scoped>
.title-bold {
    font-size: 30px;
    font-weight: bold
}

.img-fluid {
    width: 100%;
    height: auto;
}

@media (min-width: 576px) {
    .img-fluid {
        max-width: 50%;
        height: auto;
    }
}</style>